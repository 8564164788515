import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kevin/Documents/GitHub/credit101-react/src/components/blog-post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <figcaption>(CafeCredit)</figcaption>
    <p>{`You've been told a million times to start building credit, but you're never told why you need good credit. Since this website is all about credit, we want to devote this first article to convince you of the importance of building credit before getting into what you can `}<em parentName="p">{`get out of`}</em>{` your good credit. Here are a couple of reasons you should get started today.`}</p>
    <h2>{`Good Credit = Lower Interest Rates = Less Interest Paid`}</h2>
    <p>{`This is a no-brainer. (It really is.) When you borrow money from the lenders, you're charged interest when you don't pay the balance (the amount you owe) in full. With a good credit score and credit history, you can get a lower interest rate, which translates to less interest paid.`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn.vox-cdn.com/thumbor/rp-0KYsHXAmXi4MRksvU5NVNv_c=/0x0:5545x3762/1200x800/filters:focal(2330x1438:3216x2324)/cdn.vox-cdn.com/uploads/chorus_image/image/60095365/shutterstock_246334408.0.jpg",
        "alt": "House For Sale"
      }}></img></p>
    <figcaption>(Shutterstock)</figcaption>
    <p>{`Take a look at this couple, Helen and Steve, who are looking to buy their first home. The cost of their home is $300,000, and they want to apply for a 30-year, fixed-rate mortgage.`}</p>
    <p><strong parentName="p">{`Helen`}</strong>{` has an excellent credit score, so the bank gave her an interest rate of 3.67%. Suppose that Helen doesn't make any payment, the interest she can be charged annually is her current amount owed multiplied by the interest rate.`}</p>
    <p><strong parentName="p">{`Steve`}</strong>{` has a fair credit score of 620. According to data aggregated by FICO, we apply a 5.28% interest rate to the mortgage if Steve were to apply for it. We then used Google's mortgage calculator to calculate the monthly payment, total interest payment over a 30-year period, and the annualized interest payment presented in the following table:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Helen`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Steve`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credit Score`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`780`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`620`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Interest Rate (based on FICO data)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3.67%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5.28%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cost of Home`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$300,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$300,000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mortgage Period`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30 years`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30 years`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Monthly Payment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,376`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,662`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Interest Payment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$195,275`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$298,388`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Annual Interest Payment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$6,509`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$9,946`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The difference is evident. With a good credit score, Helen can save the couple about $3,473 annually in mortgage interest payment. Imagine how much you can save in interest payment for your credit cards, student loan, mortgage, auto loan, and many more.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`Cashback and Rewards Points`}</h2>
    <p><img parentName="p" {...{
        "src": "https://ubernewsroomapi.10upcdn.com/wp-content/uploads/2017/10/Header-Image-Card-Only-Photo.jpg",
        "alt": "Uber Visa Card"
      }}></img></p>
    <figcaption>Uber Visa Card (Photo by Uber)</figcaption>
    <p>{`Another benefit of having good credit is access to credit cards with great benefits. With a good credit score, you are more likely to get approved for credit cards that give you cash back as well as benefits such as travel and purchase protection. If you're like the author who dines out a lot, Barclay's Uber Visa Card can get you 4% back on every transaction you make at US restaurants. If you like traveling, the Chase Sapphire Preferred card has a signup bonus of 60,000 Ultimate Rewards points, a whopping $750 towards travel when you redeem through Chase. With a higher credit score, you can gain access to all of these credit card offers that allow you to maximize your payments.`}</p>
    <h2>{`Waived Security Deposit`}</h2>
    <p>{`Sometimes, merchants might ask for a security deposit when you have a low credit score. Cellphone carriers, for example, might refuse to give you a phone contract and ask you to choose the prepaid options. This means you'll be missing out on many of the attractive deals the carrier might offer.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block"
      }} data-ad-client="ca-pub-3524456596508824" data-ad-slot="5342728383" data-ad-format="auto" data-full-width-responsive="true">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`Renting House & Apartment`}</h2>
    <p>{`When you're renting a house or an apartment, most landlords choose to request your credit report to determine your eligibility. A good credit score can make it easier for you to rent a house or an apartment.`}</p>
    <h2>{`Employment`}</h2>
    <p>{`When applying for a job, your employer might perform credit checks to get an overall impression on your financial well-being. Note that the employer needs to get your written permission as well as inform you of how they are going to use your credit information before pulling your credit report. Your employer will only receive a summary credit report, not your credit score. Remember that you are always entitled to receive a copy of your credit report when your employer performs a credit check on you.`}</p>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block"
      }} data-ad-client="ca-pub-3524456596508824" data-ad-slot="5342728383" data-ad-format="auto" data-full-width-responsive="true">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`The Bottom Line`}</h2>
    <p>{`Building credit is important because it can save you money and from many hassles so that you can focus on what's important in your life. A good first step is to know your current credit standing. Check out this related article to learn more about getting your credit score for free:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Related: `}<a parentName="p" {...{
          "href": "https://credit101.net/score/four-ways-to-check-your-credit-score/"
        }}>{`4 Ways to Check Your Credit Score`}</a></p>
    </blockquote>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      